import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import IntegrationsBoxes from "../components/integrations/IntegrationsBoxes/IntegrationsBoxes";
import { useTranslation } from "react-i18next";

const IntegrationsPage = () => {
  const { t } = useTranslation();
  return (
    <Layout menuColor={"black"} footerColor={"aquamarine"}>
      <SEO title={t("menu.integrations")} />
      <PageHeader
        title={t("integrations.header")}
        desc={t("integrations.subheader")}
      />
      <IntegrationsBoxes />
    </Layout>
  );
};

export default IntegrationsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
