import * as styles from "./integrationsBoxes.module.scss";
import cx from "classnames";
import React from "react";

import apiIcon from "./images/api.png";
import dataIcon from "./images/data.png";
import integrationIcon from "./images/integration.png";
import qualityIcon from "./images/quality.png";
import { Trans } from "react-i18next";

const data = [apiIcon, dataIcon, integrationIcon, qualityIcon].map(
  (icon, index) => ({
    id: index + 1,
    icon,
    titleId: `integrations.feature.${index + 1}.title`,
    copyId: `integrations.feature.${index + 1}.copy`,
  })
);

const IntegrationsBoxes = () => (
  <div className={styles.featuresBoxesWrapper}>
    {data.map(({ id, icon, titleId, copyId }) => (
      <div className={styles.featureBox} key={id}>
        <div className={styles.boxIconWrapper}>
          <img src={icon} alt="" className={styles.boxIcon} />
          <div className={cx([styles[`smallDot${id}`], styles[`type${id}`]])} />
          <div className={cx([styles[`bigDot${id}`], styles[`type${id}`]])} />
        </div>
        <h3 className={styles.boxHeader}>
          <Trans>{titleId}</Trans>
        </h3>
        <p className={styles.boxDescription}>
          <Trans>{copyId}</Trans>
        </p>
      </div>
    ))}
  </div>
);

export default IntegrationsBoxes;
